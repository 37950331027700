<template>
	<div class="grid">
		<div class="col-12">
            <button class="btn btn-voltar" @click="voltarPagina()"><img src="/images/icones/seta_voltar.png"><div class="conteudo_btn_voltar">Voltar</div></button>
            <div class="div_titulo_pagina">
                <tr>
                    <td style="display:inline"><a href="/produtos"><span class="titulo_pagina">Produtos<img id="img_sinal" src="/images/icones/sinal.png"></span></a></td>
                    <td style="display:inline"><span style="color:#488EFF; font-size:14pt;">Produto</span></td>
                </tr>
                <span></span>
            </div>
			<div class="card">
                <div>
                    <h5 class="titulo_pedidos">Produto - {{produto.nome}}</h5>
                    <div class="botoes_topo" v-if="this.modo=='visualizar'">
                        <Button @click="modoEditarProduto()" label="Editar" class="p-button-primary"/>
                    </div>
                </div>
                <Loading v-if="this.loading"/>
                
                <div v-if="!loading">
                <TabView>
                    <TabPanel header="Dados Gerais">
                        <div class="grid">
                            <div class="col-6">


                                <h2>Nome</h2>
                                <p v-if="this.modo=='visualizar'"><span class="texto">{{produto.nome}}</span></p>
                                <InputText v-if="this.modo=='editar'" v-model="produto.nome" placeholder="Descrição completa do produto" type="text" />
                            </div>
                            <div class="col-2">
                                <h2>SKU</h2>
                                <p v-if="this.modo=='visualizar'"><span class="texto">{{produto.sku}}</span></p>
                                <InputText v-if="this.modo=='editar'" v-model="produto.sku" />
                            </div>
                            <div class="col-2">
                                <h2>Status</h2>
                                <p v-if="this.modo=='visualizar'"><span class="texto">{{produto.status}}</span></p>
                                <Dropdown v-if="this.modo=='editar'" v-model="produto.status" :options="status" optionLabel="name" optionValue="code" placeholder="Selecione o status" />

                            </div>
                        </div>
                        <div class="grid">
                            <!--
                            <div class="col-3">
                                <h2>Tipo</h2>
                                <p v-if="this.modo=='visualizar'"><span class="texto">Interno</span></p>
                                <div class="inputContainer" v-if="this.modo=='editar'">
                                    <Dropdown v-model="tipoCadastro" :options="tipo"
                                        placeholder="Selecione o tipo" />
                                </div>
                            </div>
                            -->
                            <div class="col-3">
                                <h2>Origem</h2>
                                <Dropdown style="width: 100%;" v-model="produto.origem" :options="origem" optionLabel="name" optionValue="code" placeholder="Selecione a Origem" />
                            </div>
                            <div class="col-3">
                                <h2>NCM</h2>
                                <p v-if="this.modo=='visualizar'"><span class="texto">{{produto.ncm}}</span></p>
                                <InputText v-if="this.modo=='editar'" v-model="produto.ncm" type="number" />
                            </div>        
                            <div class="col-3">
                                <h2>EAN</h2>
                                <p v-if="this.modo=='visualizar'"><span class="texto">{{produto.ean}}</span></p>
                                <InputText v-if="this.modo=='editar'" v-model="produto.ean" placeholder="Código (SKU) ou referência (opcional)" type="number" />
                            </div>    
                        </div>
                        
                        <h1>Dimensões</h1>
                        <div class="grid">
                            <div class="col-3">
                                <h2>Peso</h2>
                                <p v-if="this.modo=='visualizar'"><span class="texto">{{produto.altura}}</span></p>
                                <div class="p-inputgroup"  v-if="this.modo=='editar'">
                                    <InputText v-model="produto.peso" placeholder="Em cm" type="number" />
                                    <span class="p-inputgroup-addon">kg</span>
                                </div>
                            </div>
                            <div class="col-3">
                                <h2>Altura</h2>
                                <p v-if="this.modo=='visualizar'"><span class="texto">{{produto.altura}} cm</span></p>
                                <div class="p-inputgroup"  v-if="this.modo=='editar'">
                                    <InputText v-model="produto.altura" placeholder="Em cm" type="number" />
                                    <span class="p-inputgroup-addon">cm</span>
                                </div>
                            </div>

                            <div class="col-3">
                                <h2>Largura</h2>
                                <p v-if="this.modo=='visualizar'"><span class="texto">{{produto.largura}} cm</span></p>   
                                <div class="p-inputgroup" v-if="this.modo=='editar'">
                                    <InputText v-model="produto.largura" placeholder="Em cm" type="number" />
                                    <span class="p-inputgroup-addon">cm</span>
                                </div>                      
                            </div>
                            <div class="col-3">
                                <h2>Profundidade</h2>
                                <p v-if="this.modo=='visualizar'"><span class="texto">{{produto.profundidade}} cm</span></p>     
                                <div class="p-inputgroup" v-if="this.modo=='editar'">
                                    <InputText v-model="produto.profundidade" placeholder="Em cm" type="number" />
                                    <span class="p-inputgroup-addon">cm</span>
                                </div>
                            </div>
                        </div>
                        <h1>Estoque</h1>
                        <div class="grid">
                            <div class="col-3">
                                <h2>Estoque</h2>
                                <p v-if="this.modo=='visualizar'"><span class="texto">{{produto.estoque}}</span></p>     
                                <div class="p-inputgroup" v-if="this.modo=='editar'">
                                    <InputText v-model="produto.estoque" type="number" />
                                    <span class="p-inputgroup-addon">cm</span>
                                </div>
                            </div>
                        </div>
                        <h1>Imagens</h1>
                        <div class="grid">
                            <div class="col-4" v-if="this.modo=='editar'">
                                <div class="inputFile" style="margin-top:10px; height:33px !important">
                                    <div style="position:absolute; left: 50%; margin-left: -60px; margin-top: 7px">
                                        Selecionar Arquivo
                                    </div>
                                    <div style="border: #000 solid 1px">
                                        <input type="file" style="width:100%" @change="adicionarImagens" multiple>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1" v-for="(imagem, index) in this.state.imagens" :key="index" >
                                <img :src="imagem.url" class="imagem" />
                                <button class="btn_deletar_imagem">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Dados Secundários">
                        <div class="editor_texto">
                            <button class="btn_editor" title="Negrito" @click="editor_produto.chain().focus().toggleBold().run()" :disabled="!editor_produto.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor_produto.isActive('bold') }">
                                <i class="bi bi-type-bold"></i>
                            </button>
                            <button class="btn_editor" title="Itálico" @click="editor_produto.chain().focus().toggleItalic().run()" :disabled="!editor_produto.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor_produto.isActive('italic') }">
                            <i class="bi bi-type-italic"></i>
                            </button>
                            <button class="btn_editor" @click="editor_produto.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor_produto.isActive('heading', { level: 1 }) }">
                            <i class="bi bi-type-h1"></i>
                            </button>
                            <button class="btn_editor" @click="editor_produto.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor_produto.isActive('heading', { level: 2 }) }">
                            <i class="bi bi-type-h2"></i>
                            </button>
                            <button class="btn_editor" @click="editor_produto.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor_produto.isActive('heading', { level: 3 }) }">
                            <i class="bi bi-type-h3"></i>
                            </button>
                            <button class="btn_editor" title="Lista" @click="editor_produto.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor_produto.isActive('bulletList') }">
                            <i class="bi bi-list-task"></i>
                            </button>
                            <button class="btn_editor" title="Numeração" @click="editor_produto.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor_produto.isActive('orderedList') }">
                            <i class="bi bi-list-ol"></i>
                            </button>
                            <button class="btn_editor" title="Linha Horizontal" @click="editor_produto.chain().focus().setHorizontalRule().run()">
                                <i class="bi bi-dash"></i>        
                            </button>
                            <button class="btn_editor" title="Alinhamento a Esquerda" @click="editor_produto.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor_produto.isActive({ textAlign: 'left' }) }">
                            <i class="bi bi-justify-left"></i>
                            </button>
                            <button class="btn_editor" title="Alinhamento ao Centro" @click="editor_produto.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor_produto.isActive({ textAlign: 'center' }) }">
                            <i class="bi bi-text-center"></i>
                            </button>
                            <button class="btn_editor" title="Alinhamento a Direita" @click="editor_produto.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor_produto.isActive({ textAlign: 'right' }) }">
                            <i class="bi bi-justify-right"></i>
                            </button>
                            <button class="btn_editor" title="Justificado" @click="editor_produto.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor_produto.isActive({ textAlign: 'justify' }) }">
                            <i class="bi bi-justify"></i>
                            </button>
                            <button class="btn_editor" title="Voltar" @click="editor_produto.chain().focus().undo().run()" :disabled="!editor_produto.can().chain().focus().undo().run()">
                            <i class="bi bi-arrow-90deg-left"></i>
                            </button>
                            <button class="btn_editor" title="Avançar" @click="editor_produto.chain().focus().redo().run()" :disabled="!editor_produto.can().chain().focus().redo().run()">
                                <i class="bi bi-arrow-90deg-right"></i>
                            </button>
                        <editor-content :editor="editor_produto" />
                        </div>
                    </TabPanel>
                    <TabPanel header="Preços">
                        <div v-if="marketplace =! null">
                            <h4>Preço do Produto</h4>

                            <div class="grid">
                                <div class="col-3">
                                    <h2>Preço de Venda</h2>
                                    <p v-if="this.modo=='visualizar'">
                                        <span v-if="produto.preco_normal  == undefined">-----</span>
                                        <span v-else>R$ {{ produto.preco_normal}}</span>
                                    </p>
                                    <div class="p-inputgroup" v-if="this.modo=='editar'">
                                        <span class="p-inputgroup-addon">R$</span>
                                        <InputText placeholder="0,00" type="number" v-model="produto.preco_normal" />
                                    </div>
                                </div>
                            
                                <div class="col-3">
                                    <h2>Preço Promocional</h2>
                                    <p v-if="this.modo=='visualizar'">
                                        <span v-if="produto.preco_desconto  == undefined">-----</span>
                                        <span v-else>R$ {{ produto.preco_desconto}}</span>
                                    </p>
                                    <div class="p-inputgroup" v-if="this.modo=='editar'">
                                        <span class="p-inputgroup-addon">R$</span>
                                        <InputText placeholder="0,00" type="number" v-model="produto.preco_desconto" />
                                    </div>
                                </div>
                            </div>

                            <h4>Tabela de Preços</h4>

                            <div v-for="(marketplace, index) in marketplaces" :key="index" >
                                
                                <h4>{{marketplace.nome}}</h4>
                                <div class="grid">
                                    <div class="col-3">
                                        <h2>Preço de Venda  {{ precos[marketplace.id].preco_normal}}</h2>

                                        <p v-if="this.modo=='visualizar'">
                                            <span v-if="precos[marketplace.id].preco_normal  == undefined">-----</span>
                                            <span v-else>R$ {{precos[marketplace.id].preco_normal}}</span>
                                        </p>
                                        <div class="p-inputgroup" v-if="this.modo=='editar'" >
                                            <span class="p-inputgroup-addon">R$</span>
                                            <InputText placeholder="0,00" type="number" v-model="precos[marketplace.id].preco_normal" />
                                        </div>
                                    </div>  

                                    <div class="col-3">
                                        <h2>Preço Promocional</h2>
                                        <p v-if="this.modo=='visualizar'">
                                            <span v-if="precos[marketplace.id].preco_desconto  == undefined">-----</span>
                                            <span v-else>R$ {{precos[marketplace.id].preco_desconto}}</span>
                                            </p>
                                        <div class="p-inputgroup" v-if="this.modo=='editar'" >
                                            <span class="p-inputgroup-addon">R$</span>
                                            <InputText placeholder="0,00" type="number" v-model="precos[marketplace.id].preco_desconto"  />
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Atributos">
                        <table class="p-datatable-table" style="width:100%" role="rowgroup" v-if="this.modo=='visualizar'">
                            <thead class="p-datatable-thead" role="rowgroup">
                                <tr role="row">
                                    <th style="text-align: start;" :style="'width:'+nomeColuna['width']" v-for="(nomeColuna, index) in nomeColunasAtributo" :key="index" class="p-sortable-column" tabindex="0" role="cell">
                                        <div class="p-column-header-content" style="font-weight:normal">
                                            <span v-html="nomeColuna['nome']" class="p-column-title" style="font-weight:normal !important" ></span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="p-datatable-tbody" role="rowgroup" >
                                <tr v-for="(atributo, index) in produto.atributos" :key="index" class="" role="row">
                                    <td style="text-align: start;padding: 3px 10px; border-bottom: #00000050 1px solid;" v-for="(nomeColuna, index) in nomeColunasAtributo" :key="index" class=""  role="cell">{{ atributo[nomeColuna.value] }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="this.modo=='editar'">
                            <div v-for="(atributo, index) in produto.atributos" :key="index" >
                                <div class="flex inputGroup" :id="'div_editar_atributo_'+id_atributo[n]">
                                    <div style="width: 40%" class="inputContainer">
                                        <label>Atributo</label>
                                        <InputText  v-model="atributo.nome_atributo" />
                                    </div>
                                    <div class="inputContainer valor_atributos">
                                        <label>Valor</label>
                                        <InputText  v-model="atributo.valor_atributo" />
                                    </div>
                                    <div class="botoes_atributos">
                                        <button class="btn_transparente" title="Salvar Atributo" @click="salvarAtributo(nome_atributo[n], valor_atributo[n])" ><img class="icone_atributo_salvar" src="/images/icones/btn_ok.png"/> </button>
                                        <button class="btn_transparente" title="Remover Atributo" @click="deletarAtributo(id_atributo[n])" ><img class="icone_atributo_deletar" src="/images/icones/btn_excluir.png" /> </button>
                                    </div>
                                </div>
                            </div>
                            <!--
                            <Button @click="adicionarAtributo()" class="btn_add_atributos"  label="Adicionar Atributo"/>
-->
                            <div v-for="n in qtd_atributos" :key="n" >
                                <div class="flex inputGroup" :id="'div_editar_atributo_'+id_atributo[n]">
                                    <div style="width: 40%" class="inputContainer">
                                        <label>Atributo</label>
                                        <InputText  v-model="nome_atributo[n]" />
                                    </div>
                                    <div class="inputContainer valor_atributos">
                                        <label>Valor</label>
                                        <InputText  v-model="valor_atributo[n]" />
                                    </div>
                                    <div class="botoes_atributos">
                                        <button class="btn_transparente" title="Salvar Atributo" @click="salvarAtributo(nome_atributo[n], valor_atributo[n])" ><img class="icone_atributo_salvar" src="/images/icones/btn_ok.png"/> </button>
                                        <button class="btn_transparente" title="Remover Atributo" @click="deletarAtributo(id_atributo[n])" ><img class="icone_atributo_deletar" src="/images/icones/btn_excluir.png" /> </button>
                                    </div>
                                </div>
                            </div>
                            <Button @click="adicionarAtributo()" class="btn_add_atributos"  label="Adicionar Atributo"/>
                            <br />
                        </div>
                        

                    </TabPanel>

                    <TabPanel header="Anuncios">
                        <table class="p-datatable-table" style="overflow: scroll; display: block;overflow-x: auto;white-space: nowrap;" role="rowgroup">
                            <thead class="p-datatable-thead" role="rowgroup">
                                <tr role="row">
                                    <th v-for="(nomeColuna, index) in nomeColunas" :key="index" class="p-sortable-column" tabindex="0" role="cell" :style="'width:'+nomeColuna['width']">
                                        <div class="p-column-header-content">
                                            <span v-html="nomeColuna['nome']" class="p-column-title" style="font-weight:normal;" ></span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="p-datatable-tbody" role="rowgroup">
                                <tr v-for="(anuncio, index) in this.state.anuncios" :key="index" class="" role="row">
                                    <td style="text-align: start;padding: 3px 10px; border-bottom: #00000050 1px solid;" v-for="(nomeColuna, index) in nomeColunas" :key="index" class=""  role="cell">{{ anuncio[nomeColuna.value] }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <Button label="Adicionar Anúncio" class="p-button-success btn_adicionar_anuncio" @click="mostrarAdicionarAnuncio = true" v-if="this.modo=='editar'" />                            

                    </TabPanel>                    
                </TabView>
                </div>
                <div v-if="modo=='editar'">
                    <div class="div_botoes">
                        <Button label="Salvar" class="p-button-primary btn_success"  @click="salvarProduto()" />
                        <Button label="Cancelar" class="p-button-primary btn_cancel"  @click="modoVisualizarProduto()"/>
                    </div>
                </div>
			</div>
		</div>
	</div>
    <Dialog header="Adicionar Anúncio" style="width: 60%" v-model:visible="mostrarAdicionarAnuncio">
        <div class="grid inputGroup">
            <div class="inputContainer col-6" >
                <label for="origem">Marketplace</label>
                <select v-model="marketplace_id"  @change="selecionaMarketplace($event.target.selectedOptions[0].value,$event.target.selectedOptions[0].text)">
                    <option v-for="(marketplace, index) in marketplaces" :key="index" :value="marketplace.id">{{marketplace.nome}}</option>
                </select>
            </div>
            <div class="inputContainer col-6">
                <label>SKU</label>
                <InputText placeholder="" v-model="sku_anuncio"/>
            </div>
        </div>

        <div style="width: 80%" class="inputContainer">
            <label>Nome</label>
            <InputText placeholder="" v-model="nome_anuncio"/>
            <label>Caso não informada, será utilizada o nome do cadastro de produto</label>
        </div>
        <div style="width: 80%" class="inputContainer">
            <label>Descrição</label>   
            <div v-if="editor_anuncio">
                <button class="btn_editor" title="Negrito" @click="editor_anuncio.chain().focus().toggleBold().run()" :disabled="!editor_anuncio.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor_anuncio.isActive('bold') }">
                <i class="bi bi-type-bold"></i>
                </button>
                <button class="btn_editor" title="Itálico" @click="editor_anuncio.chain().focus().toggleItalic().run()" :disabled="!editor_anuncio.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor_anuncio.isActive('italic') }">
                <i class="bi bi-type-italic"></i>
                </button>
                <button class="btn_editor" @click="editor_anuncio.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor_anuncio.isActive('heading', { level: 1 }) }">
                <i class="bi bi-type-h1"></i>
                </button>
                <button class="btn_editor" @click="editor_anuncio.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor_anuncio.isActive('heading', { level: 2 }) }">
                <i class="bi bi-type-h2"></i>
                </button>
                <button class="btn_editor" @click="editor_anuncio.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor_anuncio.isActive('heading', { level: 3 }) }">
                <i class="bi bi-type-h3"></i>
                </button>
                <button class="btn_editor" title="Lista" @click="editor_anuncio.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor_anuncio.isActive('bulletList') }">
                <i class="bi bi-list-task"></i>
                </button>
                <button class="btn_editor" title="Numeração" @click="editor_anuncio.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor_anuncio.isActive('orderedList') }">
                <i class="bi bi-list-ol"></i>
                </button>
                <button class="btn_editor" title="Linha Horizontal" @click="editor_anuncio.chain().focus().setHorizontalRule().run()">
                    <i class="bi bi-dash"></i>        
                </button>
                <button class="btn_editor" title="Alinhamento a Esquerda" @click="editor_anuncio.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor_anuncio.isActive({ textAlign: 'left' }) }">
                <i class="bi bi-justify-left"></i>
                </button>
                <button class="btn_editor" title="Alinhamento ao Centro" @click="editor_anuncio.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor_anuncio.isActive({ textAlign: 'center' }) }">
                <i class="bi bi-text-center"></i>
                </button>
                <button class="btn_editor" title="Alinhamento a Direita" @click="editor_anuncio.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor_anuncio.isActive({ textAlign: 'right' }) }">
                <i class="bi bi-justify-right"></i>
                </button>
                <button class="btn_editor" title="Justificado" @click="editor_anuncio.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor_anuncio.isActive({ textAlign: 'justify' }) }">
                <i class="bi bi-justify"></i>
                </button>
                <button class="btn_editor" title="Voltar" @click="editor_anuncio.chain().focus().undo().run()" :disabled="!editor_anuncio.can().chain().focus().undo().run()">
                <i class="bi bi-arrow-90deg-left"></i>
                </button>
                <button class="btn_editor" title="Avançar" @click="editor_anuncio.chain().focus().redo().run()" :disabled="!editor_anuncio.can().chain().focus().redo().run()">
                    <i class="bi bi-arrow-90deg-right"></i>
                </button>
            </div>
            <editor-content :editor="editor_anuncio" />
            <label>Caso não informada, será utilizada a descrição do cadastro de produto</label>
        </div>
        <hr />

        <Button label="Salvar" class="p-button-success" style="margin-right:15px" @click="salvarAnuncio()" />
        <Button label="Cancelar" class="p-button-danger" @click="mostrarAdicionarAnuncio = false" />
    </Dialog>

    <Toast position="top-left" />

</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';
import { useRoute } from 'vue-router';
import { reactive } from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'

	export default {
    setup(){
        const router = useRoute()

        const state = reactive({
            imagens: [],
            anuncios:[]
        })

        return{
            router,
            state
        }
    },
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresa_id:0,
            api: "",
            loading: true,
            modo:"editar",//visualizar ou editar
            id_produto:0,
            mostrarAdicionarAnuncio:false,
            qtd_atributos:1,
            produtos: null,
            pagina: "1",
            quantidadePorPagina: "10",
            primeiroItemDaPagina: 1,
            ordenar: "id",
            tipoOrdenar: "DESC",
            status: [
                { name:'Ativo', code:'ATIVO' },
                { name: 'Inativo', code: 'INATIVO' }
            ],
            origem: [
                { name: 'Nacional', code: 'NACIONAL' },
                { name: 'Importação Direta', code: 'IMPORTACAO_DIRETA' },
                { name: 'Estrangeira Adquirida Nacionalmente', code: 'ESTRANGEIRA_ADQ_NACIONAL'}
            ],

            precos: [],
            array_teste: [
                'AA','BB','CC'
            ],
            
         //   totalLinhas: null,
            quantidades: ["10", "20", "50"],
            marketplace_id:"",
            marketplace_nome:"",
            nome_anuncio:"",
            sku_anuncio:"",
            descricao_anuncio:"",
            marketplaces:[],
            anuncios:[],
            produto:{
                anuncio:"",
                nome:"",
                descricao:  "",
                sku: "",
                ean: "",
                marca: "",
                preco_normal: "",
                preco_desconto: "",
                altura: "",
                largura : "",
                profundidade: "",
                peso: "",
                estoque: "",
                video: "",
                tipo_cadastro: "",
                status:""
            },
            nomeColunas: [
                { nome: "SKU", value: "sku",width:"20%" },
                { nome: "Título do Anúncio", value: "nome", width: "20%" },
                { nome: "MarketPlace", value: "nome_marketplace", width: "20%" },
            ],
            nomeColunasAtributo: [
                { nome: "Atributo", value: "nome_atributo",width:"50%" },
                { nome: "Valor", value: "valor_atributo",width:"50%" }
            ],
            id_atributo:[],
            nome_atributo:[],
            valor_atributo:[],
            id_preco:[],
            preco_normal:[],
            preco_desconto:[]
        };
    },
    produtosService: null,
    quantidadePaginas: 1,
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();
     //   this.api.qtdAnuncios().then(data => { this.totalLinhas = data.data.quantidade })
        
    },

    async mounted() {

        this.editor_anuncio = new Editor({
            extensions: [
                StarterKit,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
            ],
            onBlur: ({ editor }) => {
                this.descricao_anuncio = editor.getHTML();

            }
        })

        this.editor_produto = new Editor({
            extensions: [
                StarterKit,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
            ],
            onBlur: ({ editor }) => {
                this.produto.descricao = editor.getHTML();

            },
            content:'editor_produto'
        })

        if(this.$router.currentRoute.value.params.id != undefined){

            this.produto.id = this.$router.currentRoute.value.params.id
            await this.carregarProduto();
            await this.buscarEcommerce();
            

        //    await this.buscarAnuncios();
            this.modo = "editar";
            this.loading = false

        }else{
            this.produto.id = 0
            this.modo = 'editar'
            this.loading = false
        }        
    },
    methods: {
        voltarPagina(){
            this.$router.go(-1)
        },
        async carregarProduto(){
            await this.api.carregarProduto(this.token, this.produto.id, this.empresa_id).then(data => {
                this.produto = data.data.resposta
                this.state.anuncios = data.data.resposta.anuncios
                this.loading = false 
                this.state.imagens = this.produto.imagens
                
                this.editor_produto.commands.setContent(data.data.resposta.descricao)
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        adicionarImagens(event){
            for(let index = 0; index < event.target.files.length; index++){
                const element = event.target.files[index];
                var objImagem = new Object;
                objImagem.id = index +1;
                objImagem.file = element;
                objImagem.url = URL.createObjectURL(element);
                this.state.imagens.push(objImagem);
            }
        },
        carregarImagens(){

        },
        buscarEcommerce(){
            this.api.listarIntegracoes(this.token,this.empresa_id).then(data =>{
                this.marketplaces = data.data
            //    this.precos = [];

                this.api.listaPrecosPorProduto(this.token, this.empresa_id, this.produto.id).then(data => {
                    this.precos = data.data.precos;
                })

            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        deletarAtributo(atributo_id){
            if(!confirm('Deseja deletar o Atributo?')){
                //this.produto.atributos.splice(index,1)
                return;
            }
            this.api.deletarAtributo(this.empresa_id,atributo_id).then(data=>{
                if(data.data.erro == false){
                    document.querySelector('#div_editar_atributo_'+atributo_id).remove();
                }else{
                    alert('Erro ao deletar Atributo');
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        }, 

        buscarAnuncios(){
            this.api.listarAnunciosPorProduto(this.token,this.$router.currentRoute.value.params.id,this.empresa_id).then(data=>{
                console.log(data.data.resposta)
                this.anuncios = data.data.resposta
            } ).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        salvarAnuncio(){
            var preco_normal
            var preco_desconto
            this.marketplaces.forEach(marketplace => {
                if(marketplace.id == this.marketplace_id){
                    preco_normal   = this.precos[marketplace.id].preco_normal;
                    preco_desconto = this.precos[marketplace.id].preco_desconto;
                }
            });

            this.api.salvarAnuncioViaProduto(this.token ,this.empresa_id, this.$router.currentRoute.value.params.id ,this.marketplace_id, this.marketplace_nome,this.nome_anuncio,this.sku_anuncio,this.descricao,preco_normal,preco_desconto).then(data =>{
            console.log(data)
            this.sku_anuncio = "";
            this.nome_anuncio = "";
            this.descricao_anuncio = "";
            this.mostrarAdicionarAnuncio = false
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        adicionarAtributo(){
            this.qtd_atributos = this.qtd_atributos + 1;
        },
        salvarAtributo(nome_atributo,valor_atributo){
            this.api.salvarAtributoProduto(this.token,this.empresa_id,this.$router.currentRoute.value.params.id,nome_atributo, valor_atributo).then(data =>{
                console.log(data)
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        async salvarProduto() {
            
            let array_precos = [];
            this.marketplaces.forEach(marketplace => {
                let preco_marketplace = new Object();
                preco_marketplace.marketplace_id = marketplace.id;
                preco_marketplace.preco_normal = this.precos[marketplace.id].preco_normal;
                preco_marketplace.preco_desconto = this.precos[marketplace.id].preco_desconto;                
                array_precos.push(preco_marketplace);
            });
            if(array_precos.length >0){
                var teste = await this.api.updateListaPrecoProduto(this.token, array_precos, this.produto.id,this.empresa_id);
                if(teste.data.verifica_erro != false){
                    alert('Erro ao atualizar lista de preços');
                    return
                }
            }

            if (this.produto.id != 0) {
                for (let i = 0; i < this.state.imagens.length; i++) {
                    var formImagem = new FormData();
                    formImagem.append("produto_id", this.$router.currentRoute.value.params.id);
                    formImagem.append("imagem", this.state.imagens[i].file)
                    this.api.salvarImagemProduto(this.token, this.empresa_id, formImagem).then(data => {
                        console.log(data)
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            alert(this.msg_fim_sessao);
                            this.$router.push('/login');
                        } else {
                            alert(this.msg_erro);
                        }
                    });
                }    
            }
            
            if(this.produto.id != 0){
                this.api.updateProduto(this.token,this.produto,this.empresa_id).then(data =>{
                    console.log(data)
                    this.$router.push({ name: 'produtos' })
                })
            }else{
                this.api.saveProduto(this.token, this.produto,this.empresa_id).then(data_produto =>{
                    for (let i = 0; i < this.state.imagens.length; i++) {
                        var formImagem = new FormData();
                        formImagem.append("produto_id", data_produto.data.id);
                        formImagem.append("imagem", this.state.imagens[i].file)
                        this.api.salvarImagemProduto(this.token, this.empresa_id, formImagem).then(data => {
                            console.log(data)
                        }).catch((error) => {
                            if (error.response.status == 401) {
                                alert(this.msg_fim_sessao);
                                this.$router.push('/login');
                            } else {
                                alert(this.msg_erro);
                            }
                        });
                    }
                    this.$router.push({ name: 'produtos' })
                })
            }
        },

        modoEditarProduto(){
            this.modo = "editar"
        },
        modoVisualizarProduto(){
            this.carregarProduto();
            this.modo = "visualizar"
        },

        salvarPrecos(){
            let array_precos = [];
            this.marketplaces.forEach(marketplace => {
                
                let preco_marketplace = new Object();
                preco_marketplace.marketplace_id = marketplace.id;
                preco_marketplace.preco_normal = this.precos[marketplace.id].preco_normal;
                preco_marketplace.preco_desconto = this.precos[marketplace.id].preco_desconto;                
                array_precos.push(preco_marketplace);
            });
            
            this.api.updateListaPrecoProduto(array_precos,this.empresa_id,this.produto.id).then(data =>{
                if(data.data.verifica_erro == false){
                    return true
                }
                return false;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            
        },

        adicionarAnuncio() {
            this.$toast.add({ severity: "success", summary: "Sucesso", detail: "Produto Adicionado", life: 3000 });
            this.limparFormAnuncio();
        },
        limparFormAnuncio() {
            for (let field in this.produtosAdicionar) {
                this.anunciosAdicionar[field] = "";
            }
            this.mostrarAdicionarAnuncio = false;
        },
        selecionaMarketplace(id_marketplace,nome_marketplace){
            this.marketplace_id = id_marketplace
            this.marketplace_nome = nome_marketplace
        }
    },
    beforeUnmount() {
        this.editor_anuncio.destroy()
    },
    components: { Loading, EditorContent }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
<style scoped lang="css">
 


.btn_deletar_imagem{
    background-color: transparent;
    border:none;
    float:right;
    color:red;
    cursor: pointer;
}

.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}
.titulo_pedidos{
    display: inline-block; 
    width: 50%;
}
.texto{
    color:#535F74;
}
.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}
.titulo_pedidos{
    display: inline-block; 
    width: 50%;
}
.btn-voltar{
    cursor: pointer;
    background-color:#fff;

    border:none;

    padding: 8px 12px;
    border-radius: 25px;
}
input{
    margin: 0px;
    width:100%
}
.marketxplace{
    width:33%;
    display: inline-block;
    padding:15px;
}

.card_marketplace{
    width:100%;
    padding:14px;
    border-radius: 5px;
    border: #000 solid 1px;
}

.titulo_marketplace{
    font-size: 14pt;
    font-weight: bold;
}

.informacoes_anuncios .numero{
    font-size: 20pt;
    font-weight: bold;
    margin-bottom:0;
    margin-top:10px;
}
.informacoes_anuncios .titulo_informacoes{
    font-size: 12pt;
    font-weight: bold;
}

h1{
    font-size: 12pt;
    font-weight: bold;
}

h2{
    font-size: 12pt;
    font-weight: 500;
}
textarea {
  resize: none;
}
.p-tabview-nav{
    border: none !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    border: red solid 1px !important;
}
thead{
    background-color:#eff6ff;
    border-bottom: #ebeef3 solid 1px;
}
th{
    padding:5px;
    text-align: start;
}
td{
    text-align: start;
    padding: 3px 10px; 
    border-bottom: #ebeef3 1px solid; 
    width:10%
}
.btn_add_atributos{
    margin-top:10px;
    padding:6px 22px;
    background-color:#8EC253;
    border: none;
}
.btn_add_atributos:hover{
    background-color:#8EC253;
    border: none;
}
.btn_transparente{
    padding:0;
    background:none;
    border:none;
}
.btn_transparente:hover{
    cursor:pointer;
}
.botoes_atributos{
    margin-top:20px;
}
.icone_atributo_salvar{
    width:25px;
    margin-left:10px;
}
.icone_atributo_deletar{
    margin-left:8px;
}
.valor_atributos{
    width: 40%;
    margin-left:10px;
}
.btn_adicionar_anuncio{
    margin-top:10px;
    margin-top:10px;
    padding:6px 22px;
    background-color:#8EC253;
    border: none;
}
select{
    width:100%;
    border: 1px solid #ced4da;
    padding:0.75rem 0.75rem;
    background-color:#fff;
    border-radius: 6px;
    color:#495057;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.div_botoes{
    margin-bottom:10px;
    width:100%;
    text-align:center;
}
.btn_cancel{
    background-color:#9d9d9d; 
    border:#9d9d9d solid 1px;
    margin-left:10px;
    width: 15rem;
}
.btn_cancel:hover{
    background-color:#9d9d9d; 
    border:#9d9d9d solid 1px;
}
.btn_success{
    width: 15rem;
}
.conteudo_btn_voltar{
    color:#488EFF;
    font-size:14pt;
    float: left;
    margin-left:5px;
    margin-top:-2px;
}
.btn-voltar img{
    float: left;
    margin-top:3px;
}
#img_sinal{
    margin-left:6px;
    margin-right:6px;
}
.div_titulo_pagina{
    margin-top:0.6rem;
    margin-bottom:1rem;
}
.titulo_pagina{
    color:#000;
    font-size:18pt;
    font-weight:600;
}
.imagem{
    width:70%;
    float: left;
}
.inputFile{
    width: 100%;
    height: 60px;
    position:relative;
    overflow: hidden;
    border-bottom: #000 solid 1px;
}

.inputFile input{
    opacity: 0;
    filter: alpha(opacity=0);
    right:0;
    padding-top: 5px;
    z-index: 2;
    height: 80px;
    font-size:100px;
}

.btn_editor{
    background:none;
    border: #DDDDDD solid 1px;
    padding:6px;
    margin-right: 5px;
    border-radius: 5px;
    color:#394D6F;
    font-weight: bold;
}
.btn_editor :hover{
    cursor: pointer;
}
</style>

<style lang="scss">
/* Basic editor styles */

.ProseMirror {
    margin-top:10px;
    border:#ccc solid 1px;
    border-radius:3px;
    min-height: 300px;
    >*+* {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0D0D0D;
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0D0D0D, 0.1);
        margin: 2rem 0;
    }
}
</style>